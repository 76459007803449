import React, { useState } from "react";
import Layout from "../components/Layout";
import ArticleListCard from "../components/ArticleListCard";
import "../styles/Papers.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IDBArticle } from "../articles/database";
import { ordereddb, fuse } from "../articles/articles-library";

function Papers() {
  const [search, setSearch] = useState("");

  let list = ordereddb;
  let searchclassname = "searchbar";

  if (search !== "") {
    list = fuse.search(search).map((item) => item.item);
    searchclassname = "searchbar active";
  }

  const xList = list.map((article: IDBArticle) => (
    <ArticleListCard
      title={article.title}
      author={article.authors}
      link={article.link}
      img={article.image}
      date={article.date}
      isHighlighted={article.isHighlighted}
    />
  ));

  return (
    <Layout title="Papers" scrollable>
      <div className="paperspage">
        <h1>Papers list</h1>
        <div className={searchclassname}>
          <FontAwesomeIcon icon={"search"} />
          <input
            type="text"
            placeholder="search for a paper"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="paperslist">{xList}</div>
      </div>
    </Layout>
  );
}

export default Papers;
