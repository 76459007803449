import Fuse from "fuse.js";
import { fill0 } from "@ki2/utils";
import database from "./database";
import { IDBArticle } from "./database";

const options = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  minMatchCharLength: 1,
  keys: ["title", "authors"],
};

function sortDB(a: IDBArticle, b: IDBArticle) {
  let afulldate = `${fill0(a.date.year, 4)}${fill0(a.date.month, 2)}${fill0(
    a.date.day ?? 0,
    2
  )}`;
  let bfulldate = `${fill0(b.date.year, 4)}${fill0(b.date.month, 2)}${fill0(
    b.date.day ?? 0,
    2
  )}`;

  if (afulldate < bfulldate) {
    return +1;
  } else if (afulldate > bfulldate) {
    return -1;
  } else {
    return 0;
  }
}

export const ordereddb = database.sort(sortDB);
export const fuse = new Fuse(ordereddb, options);
