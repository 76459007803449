import noImage from "./noimg.jpg";
import benchphillipsimg from "../articles/Bench Phillips/fig 1.jpg";

export interface IDBArticleDate {
  day?: number;
  month: number;
  year: number;
}

export interface IDBArticle {
  id: string;
  title: string;
  authors: string;
  date: IDBArticleDate;
  link: string;
  image?: any;
  isHighlighted?: boolean;
}

const database: Array<IDBArticle> = [
  {
    id: "benchphillips",
    title:
      "Addition of bacterial filter alters positive airway pressure and non-invasive ventilation performances.",
    authors: "Rabec C, Fresnel E, Rétory Y, et al.",
    date: {
      day: 31,
      month: 1,
      year: 2022,
    },
    // link: "/article/bench-phillips",
    link: "https://erj.ersjournals.com/content/early/2022/01/20/13993003.02636-2021",
    image: benchphillipsimg,
    isHighlighted: true,
  },
  {
    id: "minimizeaerosol",
    title:
      "Recommended Approaches to Minimize Aerosol Dispersion of SARS-CoV-2 During Noninvasive Ventilatory Support Can Cause Ventilator Performance Deterioration: A Benchmark Comparative Study.",
    authors: "Patout M, Fresnel E, Lujan M, Rabec C, et al.",
    date: {
      month: 7,
      year: 2021,
    },
    link: "https://www.sciencedirect.com/science/article/pii/S0012369221004463",
  },
  {
    id: "covid19unit",
    title:
      "Mise en place d’une unité de soins intensifs respiratoire temporaire lors du premier pic épidémique de la COVID-19.",
    authors: "Patout M, Fresnel E, Kerfourn A, et al.",
    date: {
      month: 1,
      year: 2021,
    },
    link: "https://www.sciencedirect.com/science/article/pii/S1877120320305024",
  },
  {
    id: "predictcopd",
    title:
      "Prediction of severe acute exacerbation using changes in breathing pattern of COPD patients on home noninvasive ventilation.",
    authors: "Blouet S, Sutter J, Fresnel E, et al.",
    date: {
      month: 8,
      year: 2018,
    },
    link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6118244/",
  },
  {
    id: "humidsom",
    title:
      "Impact des systèmes d’humidifications en ventilation non invasive de domicile sur la qualité objective du sommeil : une étude contrôlée randomisée.",
    authors: "Patout M, Bertier A, Fresnel E et al.",
    date: {
      month: 1,
      year: 2021,
    },
    link: "https://www.sciencedirect.com/science/article/abs/pii/S1877120320303530",
  },
  {
    id: "malacie-raza-1",
    title:
      "Quel est le meilleur marqueur de la limitation de la capacité à l’effort des patients atteints de trachéobronchomalacie ?",
    authors: "Razakamanantsoa L, Fresnel E et al.",
    date: {
      month: 1,
      year: 2021,
    },
    link: "https://www.sciencedirect.com/science/article/abs/pii/S1877120320302883",
  },
  {
    id: "masks-caillard-1",
    title:
      "Mise en place et validation d’un modèle expérimental pour l’évaluation des interfaces de ventilation non invasive.",
    authors: "Caillard C, Fresnel E, Patout M et al.",
    date: {
      month: 1,
      year: 2020,
    },
    link: "https://www.sciencedirect.com/science/article/abs/pii/S1877120319317690",
  },
  {
    id: "HFO-bench",
    title:
      "High O2 flow rates required to achieve acceptable FiO2 in CPAPtreated patients with severe Covid-19: a clinically based bench study.",
    authors: "Lebret M, Fresnel E, Prieur G, et al.",
    date: {
      month: 4,
      year: 2021,
    },
    link: "https://www.researchgate.net/profile/Clement-Medrinal/publication/351333605_High_O2_Flow_Rates_Required_to_Achieve_Acceptable_FiO2_in_CPAP-Treated_Patients_With_Severe_Covid-19_A_Clinically_Based_Bench_Study/links/60918876299bf1ad8d789883/High-O2-Flow-Rates-Required-to-Achieve-Acceptable-FiO2-in-CPAP-Treated-Patients-With-Severe-Covid-19-A-Clinically-Based-Bench-Study.pdf",
  },
];

export function getArticle(id: string) {
  return database.find((article) => article.id === id);
}

export default database;
