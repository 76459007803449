import React from "react";

import Metadata from "./Metadata";
import { IDBArticleDate } from "../articles/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { limitedstring } from "../helpers";
import noImage from "../articles/noimg.jpg";

interface IArticleListCardProps {
  title: string;
  link: string;
  img?: any;
  author: string;
  date: IDBArticleDate;
  isHighlighted?: boolean;
}

function HighLightCard({
  title,
  link,
  author,
  img,
  date,
  isHighlighted,
}: IArticleListCardProps) {
  let xHighlighted = null;

  if (isHighlighted === true) {
    xHighlighted = (
      <span className="highlightedcard">
        <FontAwesomeIcon icon={"star"} /> Additional content
      </span>
    );
  }

  const { content: limitedtitle, isLimited } = limitedstring(title, 140);

  let xtooltype: any = null;
  if (isLimited) {
    xtooltype = <span className="tooltiptext">{title}</span>;
  }

  return (
    <a className="card" href={link} target="_blank">
      {xHighlighted}
      <img
        src={img ?? noImage}
        alt={title}
        className={img === undefined ? "noImage" : ""}
      />
      <div className="carddescription">
        <b>{limitedtitle}</b>
        <div className="publicationdate">
          <Metadata day={date.day} month={date.month} year={date.year}>
            {author}
          </Metadata>
        </div>
      </div>
      {xtooltype}
    </a>
  );
}

export default HighLightCard;
